<template>
  <div class="login">
    <div class="login-wrap">
      <img class="login-left" src="../assets/login-left.png" />
      <img class="login-right" src="../assets/login-right.png" />
      <img class="login-logo" src="../assets/login-logo.png" />
      <img class="login-pic" src="../assets/login-pic.png" />
      <h1>晴鑫 iot 智慧物联管理平台</h1>
      <div class="login-line"></div>
      <h2>WELCOME TO LOGIN</h2>
      <div class="login-from">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="用户名" prop="account">
            <el-input v-model="form.account"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              ref="password"
              v-model="form.password"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onLogin">提交登录</el-button>
            <el-button @click="onCancel">取消</el-button>
          </el-form-item>
        </el-form>
        <el-link href="/api/qingxin-resource/sys-file/qxkj-document/qxiot_readme.pdf" type="primary" target="_blank"><i class="el-icon-download"></i> 平台对接手册下载</el-link>
      </div>
      <div class="login-footer"><a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备20018603号-3</a> © 2022 晴鑫（廊坊）网络科技有限公司</div>
    </div>
  </div>
</template>

<style lang="less" scope>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/login-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .login-wrap {
    width: 972px;
    height: 584px;
    border-radius: 20px;
    background-color: #fff;
    position: relative;

    .login-left {
      position: absolute;
      bottom: 70px;
      left: -114px;
    }
    .login-right {
      position: absolute;
      top: 70px;
      right: -114px;
    }
    .login-logo {
      position: absolute;
      top: 40px;
      left: 60px;
    }
    .login-pic {
      position: absolute;
      top: 100px;
      left: 20px;
    }
    h1 {
      position: absolute;
      top: 120px;
      left: 580px;
      font-size: 26px;
      color: #1c50a1;
      font-weight: 400;
    }
    .login-line {
      position: absolute;
      top: 182px;
      left: 580px;
      width: 38px;
      height: 1px;
      background-color: #ccc;
    }
    h2 {
      position: absolute;
      top: 170px;
      left: 620px;
      font-size: 18px;
      color: #ccc;
      font-weight: 400;
    }
    .login-from {
      position: absolute;
      top: 220px;
      left: 580px;
      width: 320px;
      background-color: rgb(255, 255, 255);
      box-sizing: border-box;
    }
    .login-footer {
      position: absolute;
      bottom: -30px;
      width: 972px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      rules: {
        account: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 4,
            max: 20,
            message: "长度在 4 到 20 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 4,
            max: 12,
            message: "长度在 4 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      loading: null,
    };
  },
  mounted() {
    localStorage.clear();
  },
  watch: {},
  methods: {
    // 发起登录
    onLogin: function () {
      var that = this;
      var data = {
        account: that.form.account,
        password: that.form.password,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/iotUser/login",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.code == 200 && res.success == true) {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("roleLevel", res.data.roleLevel);
            localStorage.setItem("userName", res.data.userName);
            if (res.data.roleLevel == 1) {
              that.$router.push({ name: "Manufacturer" });
            } else {
              that.$router.push({ name: "Console" });
            }
          } else {
            that.$alert(res.msg, "提示", {
              confirmButtonText: "确定",
            });
            localStorage.clear();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消登录
    onCancel: function () {
      this.form.account = "";
      this.form.password = "";
      localStorage.clear();
    },
  },
};
</script>